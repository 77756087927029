<template>
    <div class="py-12 dark:bg-gray-900 bg-white">
        <div class="lg:container mx-auto  dark:text-white text-black ">

            <div class="grid grid-cols-1 gap-8 max-w-7xl mx-auto md:justify-start mx-5">
                <h1 class="lg:text-4xl sm:text-3xl mb-3 md:text-left p-2 ">Структура предприятия</h1>
            </div>

            <div class="mb-5 " v-for="(group, title) in structure" :key="group.id">
                <div class="grid grid-cols-1 gap-8 max-w-7xl mx-auto md:justify-start">
                    <h1 class="lg:text-3xl sm:text-3xl mb-10 md:text-left p-2 ">{{title}}</h1>
                </div>

                <div
                    class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 max-w-7xl mx-auto sm:justify-items-center md:justify-start">

                    <div class="max-w-sm  w-full rounded p-2 dark:bg-gray-700 bg-white shadow-md dark:shadow-none"
                         v-for="director in group" :key="director.id">
                        <div class="flex flex-col items-start h-full">
                            <div class="button-wrapper h-full">
                                <img class="h-full" v-if="director.preview_picture === ''"  :src="require('@/assets/img/logo/logo3d.svg')">
                                <img v-else :src="director.preview_picture" alt="Белтаможсервис">
                                <p class="block" @click="showModal(title, director.id)">Написать обращение</p>
                            </div>

                            <div class="flex flex-col items-start mb-5">
                                <h3 class="font-light text-lg mt-3 mb-3 leading-none">{{ director.position }}</h3>
                                <h3 class="font-semibold text-xl mb-5">{{ director.name }}</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <modal v-show="isModalVisible" @close="closeModal" :titleInput="title" :spec="spec"/>
        </div>

    </div>

</template>

<script>
import modal from '../../components/modalWindows/ModalWindowAddress.vue'
import axios from 'axios'
export default {
  name: "Structure",
  components: {
    modal,
  },
  data() {
    return {
      structure: [],
      isModalVisible: false,

      title:'',
      spec: ''
    }
  },
  created() {
    this.fetch()
  },
  
  methods: {
    async fetch() {
      axios.get('https://declarant.by/rest/company/structure')
          .then((response) => this.structure = response.data.data)
    },
    showModal(title, id) {
      this.isModalVisible = true;
      document.body.style.overflow = "hidden";
      this.title = title;
      this.spec = id;
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.style.overflow = "";
    }
  }
}
</script>

<style scoped>

.custom-style >>> *{
  line-height: inherit !important;
  font-family: "Montserrat", sans-serif!important;
  text-transform: capitalize !important;
  font-size: 16px!important;
  color: inherit!important;
}

.button-wrapper{
    position:relative;
    cursor: pointer;

}
.block{
    position:absolute;
    text-align: center;
    left:0;
    bottom:0;
    right:0;
    background: #5CB7B2;
    padding:20px;
    opacity: 0;
}

.button-wrapper:hover .block{
    opacity: 1;
    transition: opacity .7s;
       
}


</style>