<template>
    <transition name="modal">
        <div class="modal-mask overflow-auto">
            <div class="modal-wrapper">
                <div class="modal-container dark:bg-gray-800 bg-gray-100">

                    <div class="dark:text-gray-300 text-gray-700">

                        <div class="modal-header flex items-center">
                            <h1 class="text-center lg:text-3xl sm:text-xl w-4/5">Электронное обращение</h1>
                            <CloseButton @close="$emit('close')"></CloseButton>
                        </div>

                        <div class="modal-body overflow-hidden">
                            <h3>Обратиться к специалистам подразделения:</h3> <br>
                            <input v-if="titleInput" type="text" class="inputs dark:bg-gray-700 text-white outline-none"
                                   :placeholder=titleInput disabled>
                            <input type="text" class="inputs dark:bg-gray-700 outline-none"
                                   placeholder="Фамилия Имя Отчество *" v-model.trim="form.fio"
                                   :class="$v.form.fio.$error ? 'invalid' : ''">
                            <p v-if="$v.form.fio.$dirty && !$v.form.fio.required" class="text-red-500 mb-3 text-sm">
                                обязательно для заполнения*</p>

                            <input type="text" class="inputs dark:bg-gray-700 outline-none"
                                   placeholder="Адрес электронной почты *" v-model.trim="form.email"
                                   :class="$v.form.email.$error ? 'invalid' : ''">
                            <p v-if="$v.form.email.$dirty && !$v.form.email.required" class="text-red-500 mb-3 text-sm">
                                обязательно для заполнения*</p>
                            <p v-if="$v.form.email.$dirty && !$v.form.email.email" class="text-red-500 mb-3">введите
                                корректный адрес</p>
                            <input type="text" class="inputs dark:bg-gray-700 outline-none"
                                   placeholder="Контактный номер телефона *" v-model.trim="form.phone"
                                   :class="$v.form.phone.$error ? 'invalid' : ''">
                            <p v-if="$v.form.phone.$dirty && !$v.form.phone.required" class="text-red-500 mb-3 text-sm">
                                обязательно для заполнения*</p>
                            <textarea class="inputs dark:bg-gray-700 outline-none" cols="20" rows="3"
                                      placeholder="Краткое содержание обращения" v-model.trim="form.message"></textarea>
                            <!-- <p v-if="$v.form.message.$dirty && $v.form.message.url" class="text-red-500 mb-3">не должно содержать ссылок</p> -->
                            <div class="mb-3">
                                <vue-dropzone ref="adress"
                                              class="dark:bg-gray-800 dark:hover:border-gray-700 hover:cursor-pointer h-full rounded"
                                              id="dropzone2"
                                              :options="dropzoneOptions"></vue-dropzone>
                            </div>
                            <h3>Ответ направить на</h3> <br>
                            <div class="flex flex-col">
                                <div>
                                    <input type="radio" id="r1"
                                           name="send" value="mail" v-model="form.send">
                                    <label id="l1" class="ml-5" for="r1">Электронную почту</label>
                                </div>
                                <div>
                                    <input type="radio" id="r2"
                                           name="send" value="adress" v-model="form.send">
                                    <label id="l2" class="ml-5" for="r2">Адрес места жительства</label>
                                </div>
                            </div>
                            <div class="mt-4">
                                <input class="mr-3" type="checkbox" id="c1" v-model="form.agree">
                                <label id="p1" for="c1" class="text-sm">даю свое согласие на обработку персональных
                                    данных и соглашаюсь с политикой конфиденциальности и политикой в отношении обработки
                                    персональных данных</label>
                            </div>
                        </div>

                        <div class="modal-footer text-center">
                            <button @click="chekForm" type="submit" v-show="form.agree"
                                    class="py-3 px-6 bg-blue-300 sm:m-10 lg:m-0 hover:bg-teal-300 font-medium cursor-pointer rounded transition duration-500 text-black">
                                Отправить
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CloseButton from '../common/CloseButton'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from 'axios'
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

export default {
    props: ['titleInput', 'spec'],
    name: 'modal',
    components: {
        vueDropzone: vue2Dropzone,
        CloseButton
    },
    mixins: [validationMixin],
    data() {
        return {
            dropzoneOptions: {
                url: 'https://declarant.by/api/user/attach-documents.php',
                autoProcessQueue: false,
                thumbnailWidth: 150,
                maxFilesize: 50000000,
                maxFiles: 1,
                uploadMultiple: true,
                dictDefaultMessage: 'Перетащите файлы для загрузки',
                dictMaxFilesExceeded: 'Разрешен только один файл'
            },
            file: [],
            form: {
                top: '',
                fio: '',
                email: '',
                phone: '',
                message: '',
                send: '',
                agree: '',
                spec: ''
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        chekForm() {
            this.$v.form.$touch()
            if (!this.$v.form.$error) {
                this.postForm()
                this.$emit('close');
            }
        },
        postForm() {
            this.form.top = this.titleInput
            this.form.spec = this.spec
            this.file = this.$refs.adress.getQueuedFiles()
            this.form.email = btoa(this.form.email)
            let data = new FormData()

            data.append('form', JSON.stringify(this.form))
            data.append('file', this.file[0])

            axios({
                method: 'post',
                url: 'https://declarant.by/rest/forms/appointment',
                data: data,
                headers: {
                    "Content-type": 'multipart/form-data'
                }
            }).then(function (res) {
                this.$notify({
                    title: 'Успешно',
                    text: res.data.data,
                    type: 'success'
                })
            })

        }

    },
    validations() {
        return {
            form: {
                fio: {required},
                email: {required, email},
                phone: {required},
            }
        }
    }
};
</script>

<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}


.modal-container {
    width: 40%;
    margin: 60px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-body {
    margin: 20px 0;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.inputs {
    margin-bottom: 15px;
    border: 1px solid rgba(155, 155, 155, 0.4);
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    transition: .5s;
}

label:hover {
    cursor: pointer;
    color: rgb(94 234 212);
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
    border: 1px solid #2196F3;
}

#r2:checked ~ #l2, #r1:checked ~ #l1, #c1:checked ~ #p1 {
    color: #2196F3;
}

.invalid {
    background-color: #ffdddd;
    margin-bottom: 0px;
}

.dropzone {
    min-height: 50px;
}

</style>