import { render, staticRenderFns } from "./ModalWindowAddress.vue?vue&type=template&id=096c4a42&"
import script from "./ModalWindowAddress.vue?vue&type=script&lang=js&"
export * from "./ModalWindowAddress.vue?vue&type=script&lang=js&"
import style0 from "./ModalWindowAddress.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports